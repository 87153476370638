import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import ButtonCard from '../components/ButtonCard';
import Layout from '../components/Layout/Layout';

const Portal = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-center mb-8">
          Welcome back, {user.username}!
        </h1>
        <div className="flex flex-wrap justify-center">
          <ButtonCard
            title="Order Form"
            description="Place a new order."
            linkTo="/order-form"
          />
          <ButtonCard
            title="Profile"
            description="View or edit your profile."
            linkTo="/profile"
          />
          <ButtonCard
            title="Settings"
            description="Adjust your preferences."
            linkTo="/settings"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Portal;
