// pages/upload.jsx
import React from 'react';
import Layout from '../components/Layout/Layout';
import FileUploader from '../components/FileUploader';

export default function Upload() {
  return (
    <>
      <Layout>
      <FileUploader />
      </Layout>
    </>
  );
}
