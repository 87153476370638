// components/FileUploader.jsx
import React, { useState } from 'react';
import JSZip from 'jszip';
import { uploadData } from '@aws-amplify/storage';

const FileUploader = ({ label, uploadPath, onUploadComplete, isImage = false }) => {
  const [processing, setProcessing] = useState(false);
  const [processingProgress, setProcessingProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [fileName, setFileName] = useState('')

  const processVideo = async (file) => {
    setProcessing(true);
    setProcessingProgress(0);

    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);
    video.crossOrigin = 'anonymous';

    await new Promise((resolve) => (video.onloadedmetadata = resolve));

    const duration = video.duration;
    const fps = 30; 
    const frameInterval = 10; 

    const totalFrames = Math.floor((duration * fps) / frameInterval);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const zip = new JSZip();

    for (let frame = 0; frame < totalFrames; frame++) {
      const time = (frame * frameInterval) / fps;
      video.currentTime = time;

      await new Promise((resolve) => (video.onseeked = resolve));

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const dataURL = canvas.toDataURL('image/webp', 0.8); 
      const imageData = atob(dataURL.split(',')[1]);
      const uint8Array = new Uint8Array(imageData.length);

      for (let i = 0; i < imageData.length; i++) {
        uint8Array[i] = imageData.charCodeAt(i);
      }

      zip.file(`frame_${frame * frameInterval}.jpg`, uint8Array);

      const progressPercent = Math.round(((frame + 1) / totalFrames) * 100);
      setProcessingProgress(progressPercent);
    }

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    setProcessing(false);
    setProcessingProgress(100);
    return zipBlob;
  };

  const handleFileChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      setCompleted(false);
      const file = e.target.files[0];

      try {
        let dataToUpload;
        let finalFileName;

        if (!isImage) {
          // Video processing logic
          dataToUpload = await processVideo(file);
          finalFileName = file.name.replace('.mp4', '_frames.zip');
        } else {
          // Image logic: no processing, upload the image directly
          dataToUpload = file;
          finalFileName = file.name;
        }

        setUploading(true);
        setUploadProgress(0);

        const task = uploadData({
          path: ({ identityId }) =>
            `private/${identityId}/${uploadPath}_${finalFileName}`,
          data: dataToUpload,
          options: {
            contentType: isImage ? file.type : 'application/zip',
            accessLevel: 'private',
            onProgress: ({ transferredBytes, totalBytes }) => {
              if (totalBytes) {
                const percentCompleted = Math.round(
                  (transferredBytes * 100) / totalBytes
                );
                setUploadProgress(percentCompleted);
              }
            },
          },
        });

        const result = await task.result;

        if (onUploadComplete) {
          onUploadComplete(result);
          setFileName(finalFileName)
        }


        

        // alert(`${label} file uploaded successfully!`);
        setCompleted(true);
      } catch (err) {
        console.error(err);
        alert(`Error processing or uploading ${label} file.`);
      } finally {
        setProcessing(false);
        setUploading(false);
        setProcessingProgress(0);
        setUploadProgress(0);
        e.target.value = null; 
      }
    }
  };

  return (
    <div className="w-full mb-4">
      <label className="block text-gray-700 font-bold mb-2">{label}</label>

      {completed && !uploading && !processing ? (
        <div className="mt-2 text-center text-green-600 font-bold">
          {fileName} Upload Complete!
        </div>
      ):(
        <>
        <div className="flex items-center">
        <input
          type="file"
          accept={isImage ? 'image/*' : 'video/*'}
          onChange={handleFileChange}
          disabled={processing || uploading}
          className="w-full"
        />
      </div>

      {processing && (
        <div className="mt-2">
          <p className="text-center text-black">
            Processing frames... {processingProgress}%
          </p>
          <div className="w-full bg-gray-200 rounded-full h-4">
            <div
              className="h-4 rounded-full bg-yellow-500"
              style={{ width: `${processingProgress}%` }}
            ></div>
          </div>
        </div>
      )}

      {uploading && (
        <div className="mt-2">
          <p className="text-center text-black">
            Uploading... {uploadProgress}%
          </p>
          <div className="w-full bg-gray-200 rounded-full h-4">
            <div
              className="h-4 rounded-full bg-customTeal"
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
        </div>
      )}</>
      )}

      

      
    </div>
  );
};

export default FileUploader;
