// src/components/RequireAuth.js
import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Login from './Login';

const RequireAuth = ({ children }) => {
  const { route } = useAuthenticator((context) => [context.route]);

  if (route !== 'authenticated') {
    return <Login />
  }

  return children;
};

export default RequireAuth;
