// src/pages/login.jsx
import React from 'react';
import '../styles/custom-authenticator.css';
import { Authenticator, ThemeProvider, useTheme } from '@aws-amplify/ui-react';

const Login = () => {
  const theme = {
    name: 'custom-theme',
    tokens: {
      colors: {
        background: {
          primary: { value: '#ffffff' },
        },
        font: {
          primary: { value: '#000000' },
        },
        // Customize other colors as needed
      },
      components: {
        button: {
          primary: {
            backgroundColor: { value: '#1e90ff' },
            color: { value: '#ffffff' },
            _hover: {
              backgroundColor: { value: '#1c86ee' },
            },
          },
        },
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="login-container">
        <Authenticator>
          {({ signOut, user }) => (
            <div className="auth-content">
              <h2>Welcome, {user.username}</h2>
              <button onClick={signOut}>Sign Out</button>
            </div>
          )}
        </Authenticator>
      </div>
    </ThemeProvider>
  );
};

export default Login;
