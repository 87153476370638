import React from 'react';
import Feature from "../components/Feature";
import Pricing from "../components/Pricing";
import HeroVideo from "../components/HeroVideo";
import Layout from "../components/Layout/Layout";

export default function Home() {


  return (
    <>
      <Layout>
        <HeroVideo />
        <Feature />
        <Pricing />
      </Layout>
    </>
  );
}
