// src/components/Portal/ButtonCard.js
import React from 'react';
import { Link } from 'react-router-dom';

const ButtonCard = ({ title, description, linkTo }) => {
  return (
    <div className="button-card">
      <div className="px-6 py-4">
        <div className="button-card-title mb-2">{title}</div>
        <p className="button-card-description text-base">{description}</p>
      </div>
      <div className="px-6 py-4">
        <Link to={linkTo} className="button-card-button">
          Go to {title}
        </Link>
      </div>
    </div>
  );
};

export default ButtonCard;
