import React, { useMemo } from "react";
import { motion } from "framer-motion";
import getScrollAnimation from "../utils/getScrollAnimation";
import { useAuthenticator } from "@aws-amplify/ui-react";
import ButtonPrimary from "./Misc/ButtonPrimary";
import ScrollAnimationWrapper from "./Layout/ScrollAnimationWrapper";

const HeroVideo = () => {
  const scrollAnimation = useMemo(() => getScrollAnimation(), []);
  const { user } = useAuthenticator((context) => [context.user]);

  return (
    <div className="relative h-screen w-full overflow-hidden" id="about">
      {/* Background video */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="https://evolver-miscellaneous.s3.us-east-2.amazonaws.com/backgroundVideo.mp4"
        crossorigin="anonymous"
        autoPlay
        loop
        muted
        playsInline
      />

      {/* Overlay content */}
      <div className="relative z-10 flex items-center justify-center h-full">
        <ScrollAnimationWrapper>
          <motion.div
            className="text-center max-w-2xl mx-auto p-8"
            variants={scrollAnimation}
          >
            <h1 className="text-4xl lg:text-5xl font-bold leading-tight text-white-500" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>
              Complete rendering solution from <strong><span style={{ color: '#00E3E3' }}>A</span> to <span style={{ color: '#00E3E3' }}>Z</span></strong>.
            </h1>
            <p className="text-white-500 mt-4 mb-6">
              Using EvolverFlow we visualize your whole area within hours.
            </p>
            {user ? (
              <div>
                <p className="text-white-500">Welcome back, {user.username}!</p>
                {/* Additional content for logged-in users */}
              </div>
            ) : (
              <ButtonPrimary>Get Started</ButtonPrimary>
            )}
          </motion.div>
        </ScrollAnimationWrapper>
      </div>

      {/* Background overlay for darkening effect */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-40"></div>
    </div>
  );
};

export default HeroVideo;
