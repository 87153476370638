// pages/login.jsx
import React from 'react';
import LoginComponent from '../components/Login';
import Layout from '../components/Layout/Layout';
// import SeoHead from '../components/SeoHead';

export default function LoginPage() {
  return (
    <>
      {/* <SeoHead title="Login - EvolverFlow" /> */}
      <Layout>
        <div className="flex items-center justify-center min-h-screen bg-white mt-24" >
          <LoginComponent />
        </div>
        
      </Layout>
    </>
  );
}
