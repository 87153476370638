// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Upload from './pages/Upload';
import Login from './pages/Login';
import './index.css';
import Portal from './pages/Portal';
import { Amplify } from 'aws-amplify'; 
import { Authenticator } from '@aws-amplify/ui-react';
import awsExports from './amplifyconfiguration.json';
import RequireAuth from './components/RequireAuth';
import OrderForm from './pages/OrderForm'

Amplify.configure(awsExports);


function App() {
  return (
    <Authenticator.Provider>
      <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/portal"
              element={
                <RequireAuth>
                  <Portal />
                </RequireAuth>
              }
            />
            <Route
              path="/order-form"
              element={
                <RequireAuth>
                  <OrderForm />
                </RequireAuth>
              }
            />
          </Routes>
      </Router>
    </Authenticator.Provider>
  );
}

export default App;
