// pages/orderform.js
import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import FileUploader from '../components/FileUploader';

const OrderForm = () => {
  const [companyName, setCompanyName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [uploads, setUploads] = useState({
    product: null,
    building: null,
    area: null,
    background: null,
    design: null,
  });
  const [submitted, setSubmitted] = useState(false); // New state for submission

  const handleUploadComplete = (type) => (result) => {
    const value = result.path;
    setUploads((prevUploads) => ({
      ...prevUploads,
      [type]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const allFilesUploaded = Object.values(uploads).every((file) => file !== null);
    if (!allFilesUploaded) {
      alert('All upload fields are required/Please let the files upload first.');
      return;
    }

    const formId = '1FAIpQLScU5UQVhYcxIv9kVmDY8YiOxR4-itzbnX5tQWJJeD9jANh2uQ';
    const formAction = `https://docs.google.com/forms/d/e/${formId}/formResponse`;
    const formData = new FormData();
    formData.append('entry.1802208956', uploads.product);
    formData.append('entry.1096840975', uploads.building);
    formData.append('entry.1221502708', uploads.area);
    formData.append('entry.1493995173', uploads.background);
    formData.append('entry.712719117', uploads.design);
    formData.append('entry.1626505983', companyName);
    formData.append('entry.268040468', projectName);

    try {
      await fetch(formAction, {
        method: 'POST',
        body: formData,
        mode: 'no-cors'
      });
      // Clear states
      setCompanyName('');
      setProjectName('');
      setUploads({
        product: null,
        building: null,
        area: null,
        background: null,
        design: null,
      });

      // Set submitted state to true
      setSubmitted(true);
    } catch (error) {
      alert('Error submitting form');
    }
  };

  const handleCreateAnotherOrder = () => {
    // Reset form for a new order
    setCompanyName('');
    setProjectName('');
    setUploads({
      product: null,
      building: null,
      area: null,
      background: null,
      design: null,
    });
    setSubmitted(false);
  };

  return (
    <Layout>
      <div className="min-h-screen flex items-center justify-center">
        <div className="container mx-auto p-4 max-w-lg bg-white shadow-md rounded-lg">
          {!submitted ? (
            <>
              <h1 className="text-2xl font-bold mb-4 text-center">Order Form</h1>
              <form onSubmit={handleSubmit} className="space-y-6">
                <FileUploader
                  label="Product"
                  uploadPath={'product'}
                  onUploadComplete={handleUploadComplete('product')}
                />
                <FileUploader
                  label="Building"
                  uploadPath={'building'}
                  onUploadComplete={handleUploadComplete('building')}
                />
                <FileUploader
                  label="Area"
                  uploadPath={'area'}
                  onUploadComplete={handleUploadComplete('area')}
                />
                <FileUploader
                  label="Background"
                  uploadPath={'background'}
                  onUploadComplete={handleUploadComplete('background')}
                />
                <FileUploader
                  label="Design Photo"
                  uploadPath={'design'}
                  isImage={true}
                  onUploadComplete={handleUploadComplete('design')}
                />
        
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">
                    Company Name
                  </label>
                  <input
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="w-full px-3 py-2 border rounded-md"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">
                    Project Name
                  </label>
                  <input
                    type="text"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    className="w-full px-3 py-2 border rounded-md"
                    required
                  />
                </div>
        
                <button
                  type="submit"
                  className="w-full px-4 py-2 font-semibold text-white rounded-md bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                  Submit
                </button>
              </form>
            </>
          ) : (
            <div className="text-center">
              <h2 className="text-2xl font-bold text-green-600 mb-4">Order Submitted!</h2>
              <p className="mb-4">Your order has been successfully submitted.</p>
              <button
                onClick={handleCreateAnotherOrder}
                className="px-4 py-2 font-semibold text-white rounded-md bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
              >
                Create Another Order
              </button>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default OrderForm;
